import styled from 'styled-components'
import { Text } from 'components/Text'

export const HeadingMain = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.text};
`

export const Paragaph = styled(Text)`
font-family: ${({ theme }) => theme.fonts.primary};
font-weight: 300; 
font-size: 14px; 
line-height: 21px;
margin-top: 12px;
color: ${({ theme }) => theme.colors.info};
.white{
color: ${({ theme }) => theme.colors.text};
}
.link-color{
    color: #0094FF;
}
`

export const Headingparagraph = styled(Text)`
font-family: ${({ theme }) => theme.fonts.primary};
color: ${({ theme }) => theme.colors.text};
font-weight: 500; 
font-size: 24px; 
margin-top: 20px;
line-height: 36px;
.space {
    line-height: 20px;
}
`
export const List = styled(Text)`
font-family: ${({ theme }) => theme.fonts.primary};
color: ${({ theme }) => theme.colors.info};
font-weight: 500;
font-size: 16px;
line-height: 24px;
margin-top: 10px;
margin-bottom: 16px;
justify-content: center;
align-items: center;
.listSeries{
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    padding-right: 40px;
    position: relative;
    background: linear-gradient(133.82deg,#513CFF 13.2%,#23ABD4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.listinnerSeries{
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    padding-left: 45px;
    position: relative;
    top: 3px;
    background: linear-gradient(133.82deg,#513CFF 13.2%,#23ABD4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.listBottom{
    margin-bottom: 300px;
    margin-top: 56px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
span{
    color: ${({ theme }) => theme.colors.text};
}
`