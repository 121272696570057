import { Flex } from 'components/Box'
import useViewport from 'hooks/useViewport'
import { PageContainer } from 'views/Project/styles'
import { RightLight, TopLight } from 'views/Profile/styles'
import { HeadingMain, Paragaph, Headingparagraph, List } from './styles'

const Privacypolicy = () => {
    const { width } = useViewport()
    const isMobile = width <= 990
    const IsTab = width <= 1640 && width > 990
  return (
    <>
    <PageContainer mr={['8px', 0]} width={isMobile ? '90%' : IsTab ? '88%' : '78%'}>
        <Flex>
          <HeadingMain>
            NEXA PRIVACY POLICY
          </HeadingMain>
        </Flex>
        <Flex flexDirection={'column'}>
            <Paragaph>We recognise our responsibilities in relation to the collection, holding, processing, use and/or transfer of personal data. Your privacy is of utmost importance to us.</Paragaph>
            <Paragaph> This policy (the Policy) outlines how we collect, use, store and disclose your personal data. Please take a moment to read about how we collect, use and/or disclose your personal data so that you know and understand the purposes for which we may collect, use and/or disclose your personal data. By accessing the website at <a className='link-color' href = 'https://www.nexa.network/'> https://nexa.network </a> any sub-domains (the Website), you agree and consent to Ratchet, Crank, and Partners S.A. (the Company), its related corporations, business units and affiliates, as well as their respective representatives and/or agents (collectively referred to herein as Nexa, us, we or our), collecting, using, disclosing and sharing amongst themselves the personal data, and to disclosing such personal data to relevant third party providers. This Policy supplements but does not supersede nor replace any other consent which you may have previously provided to us nor does it affect any rights that we may have at law in connection with the collection, use and/or disclosure of your personal data. We may from time to time update this Policy to ensure that this Policy is consistent with our future developments, industry trends and/or any changes in legal or regulatory requirements. Subject to your rights at law, the prevailing terms of this Policy shall apply. For the avoidance of doubt, this Policy forms part of the terms and conditions governing your relationship with us and should be read in conjunction with such terms and conditions. </Paragaph>
            <Paragaph>The security of your personal data is important to us. At each stage of data collection, use and disclosure, Nexa has in place physical, electronic, administrative and procedural safeguards to protect the personal data stored with us. However, do note that no transmission of personal data over the internet can be guaranteed to be 100% secure – accordingly and despite our efforts, Nexa cannot guarantee or warrant the security of any information you transmit to us, or to or from our online services. Nexa shall not have any responsibility or liability for the security of information transmitted via the internet.</Paragaph>
            <Paragaph> This Policy describes how Nexa may collect, use, disclose, process and manage your personal data, and applies to any individual’s personal data which is in our possession or under our control.</Paragaph>
        </Flex>
        <Flex>
            <Headingparagraph>
                What personal data is collected by Nexa
            </Headingparagraph>
        </Flex>
        <Flex flexDirection={'column'}>
            <Paragaph>"Personal data" means data, whether true or not, about an individual who can be identified (i) from that data, or (ii) from that data and other information to which the organisation has or is likely to have access. Some examples of personal data that Nexa may collect are:</Paragaph>
        </Flex>
        <Flex flexDirection={'column'}>
          <List>
            <span className='listSeries'>(a)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> information about your use of our services and Website, and specific user interactions with the Website such as features utilised, areas visited or clicked on, and time spent; </Flex>
          </List>
          <List>
            <span className='listSeries'>(b)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> usernames and password, third party account credentials (such as your Facebook login credentials, Google login credentials), Internet Protocol address (IP address), and geographical location; </Flex>
          </List>
          <List>
            <span className='listSeries'>(c)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> browser type and version, operating system used by the accessing system, internet service provider of the accessing system, the website from which an accessing system reaches our website (i.e. "referrers"), location of access, or other similar data and information; </Flex>
          </List>
          <List>
            <span className='listSeries'>(d)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> behaviour such as pages (and sub-page) visited, time and date of your visit, time spent on each page, and other diagnostic data; </Flex>
          </List>
          <List>
            <span className='listSeries'>(e)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> particulars of digital wallet addresses (including transactions performed by said digital wallet addresses), public cryptographic key relating to digital wallet addresses on distributed ledger networks and/or similar information; and/or </Flex>
          </List>
          <List>
            <span className='listSeries'>(f)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> personal opinions made known to us (e.g. feedback or responses to surveys). </Flex>
          </List>
          <Paragaph>Personal data may be collected when you interact with our services or use the Website, or may be received by Nexa from third-party databases or service providers that provide business information.<br/><br/>
          In particular, Projects on Nexa would work with authorised third-party "Know Your Customer" ("KYC") / "Anti Money Laundering" ("AML") service providers in order to perform checks to comply with KYC/AML laws in all applicable jurisdictions, and may request personal information in connection with such checks, including without limitation name, alias(es), gender, contact details, residential address, date of birth, identity card/passport details, social media handles and other social media profile information, and/or education details, e-mail address, or financial details (e.g. income, wealth, source of funds/wealth and bank information), your images and voice/video recordings.</Paragaph>
          <Headingparagraph>Personal data and the Blockchain</Headingparagraph>
          <Paragaph>
            Blockchain technology, also known as distributed ledger technology (DLT), is at the core of our business. Blockchains are decentralized and made up of digitally recorded data in a chain of packages called "blocks". The manner in which these blocks are linked is chronological, meaning that the data is very difficult to alter once recorded. Since the ledger may be distributed all over the world (across several "nodes" which usually replicate the ledger) this means there is no single person making decisions or otherwise administering the system (such as an operator of a cloud computing system), and that there is no centralized place where it is located either.<br/><br/>
            Accordingly, by design, a blockchain’s data cannot be changed or deleted and is said to be "immutable". This may affect your ability to exercise your rights such as your right to erasure ("right to be forgotten"), or your rights to object or restrict processing of your personal data. Data on the blockchain cannot be erased and cannot be changed. Although smart contracts may be used to revoke certain access rights, and some content may be made invisible to others, it is not deleted.<br/><br/>
            In certain circumstances, in order to comply with our contractual obligations to you (such as delivery of tokens or provision of other services) it will be necessary to collect certain personal data, such as your wallet address, onto the blockchain; this is done through a smart contract and requires you to execute such transactions using your wallet’s private key.<br/><br/>
            The ultimate decisions to (a) transact on the blockchain using your wallet address, as well as (b) share the public key relating to your wallet address with anyone (including us) rests with you.<span className='white'> IF YOU WANT TO ENSURE YOUR PRIVACY RIGHTS ARE NOT AFFECTED IN ANY WAY, YOU SHOULD NOT TRANSACT ON BLOCKCHAINS AS CERTAIN RIGHTS MAY NOT BE FULLY AVAILABLE OR EXERCISABLE BY YOU OR US DUE TO THE TECHNOLOGICAL INFRASTRUCTURE OF THE BLOCKCHAIN. IN PARTICULAR THE BLOCKCHAIN IS AVAILABLE TO THE PUBLIC AND ANY PERSONAL DATA SHARED ON THE BLOCKCHAIN WILL BECOME PUBLICLY AVAILABLE.</span><br/>
          </Paragaph>
          <Headingparagraph>Purposes for collection, use and disclosure of your personal data</Headingparagraph>
          <Paragaph>Nexa may collect, use and/or disclose your personal data for its legitimate interests or business purposes, including operations for these purposes. These may include, without limitation, the following:</Paragaph>
          <List>
            <span className='listSeries'>(a)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> developing and providing facilities, products or services (whether made available by us or through us) or your participation in interactive features of our services, including without limitation: </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(i)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> services related to the acquisition of digital assets or participation in digital asset projects; </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(ii)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> acting as intermediaries through any blockchain, network or platform; </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(iii)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> recording and/or encryption on any blockchain, network or platform; </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(iv)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> promoting advertisements or marketing material, whether from us or third parties; </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(v)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> various products and/or services (whether digital or not, and whether provided through an external service provider or otherwise); </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(vi)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> making payments for participation in any blockchain, network or platform developed or managed by us (as applicable); </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(vii)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> any escrow, courier, anti-counterfeiting, cyber-security or dispute resolution services; </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(viii)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> transactions and clearing or reporting on these transactions; </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(ix)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> carrying out research, planning and statistical analysis; </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(x)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> trouble-shooting, technical maintenance and bug fixes; and/or </Flex>
          </List>
          <List>
            <span className='listinnerSeries'>(xi)</span> 
            <Flex alignItems={'center'} padding={'0 110px'} marginTop={'-30px'}> analytics for the purposes of developing or improving our products, services, security, service quality, staff training, and advertising strategies; </Flex>
          </List>
          <List>
            <span className='listSeries'>(b)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> assessing and processing applications, instructions, transactions, or requests from you or our customers; </Flex>
          </List>
          <List>
            <span className='listSeries'>(c)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> communicating with you, including providing you with updates on changes to services or products (whether made available by us or through us) including any additions, expansions, suspensions and replacements of or to such services or products and their terms and conditions; </Flex>
          </List>
          <List>
            <span className='listSeries'>(d)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> managing our infrastructure and business operations and complying with internal policies and procedures; </Flex>
          </List>
          <List>
            <span className='listSeries'>(e)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> responding to queries or feedback; </Flex>
          </List>
          <List>
            <span className='listSeries'>(f)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> addressing or investigating any complaints, claims or disputes in connection with the services; </Flex>
          </List>
          <List>
            <span className='listSeries'>(g)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> verifying your identity for the purposes of providing facilities, products or services, which would require comparison of your personal information against third party databases and/or provision of such information to third party service providers; </Flex>
          </List>
          <List>
            <span className='listSeries'>(h)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> conducting credit checks, screenings or due diligence checks as may be required under applicable law, regulation or directive; </Flex>
          </List>
          <List>
            <span className='listSeries'>(i)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> complying with all applicable laws, regulations, rules, directives, orders, instructions and requests from any local or foreign authorities, including regulatory, governmental, tax and law enforcement authorities or other authorities; </Flex>
          </List>
          <List>
            <span className='listSeries'>(j)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> monitoring products and services provided by or made available through us; </Flex>
          </List>
          <List>
            <span className='listSeries'>(k)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> financial reporting, regulatory reporting, management reporting, risk management (including monitoring credit exposures, preventing, detecting and investigating crime, including fraud and any form of financial crime), audit and record keeping purposes; </Flex>
          </List>
          <List>
            <span className='listSeries'>(l)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> enabling any actual or proposed assignee or transferee, participant or sub-participant of Nexa's rights or obligations to evaluate any proposed transaction; </Flex>
          </List>
          <List>
            <span className='listSeries'>(m)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> enforcing obligations owed to us, protecting our rights or property, and protecting against legal liability; and/or </Flex>
          </List>
          <List>
            <span className='listSeries'>(n)</span> 
            <Flex alignItems={'center'} padding={'0 70px'} marginTop={'-30px'}> seeking professional advice, including legal or tax advice. </Flex>
          </List>
          <Paragaph>We may also use personal data for purposes set out in the terms and conditions that govern our relationship with you or our customer.</Paragaph>
          <Headingparagraph>eKYC</Headingparagraph>
          <Paragaph>
            For certain product offerings, Nexa may engage and authorise certain third party service providers of electronic know-your-client (eKYC) services for identity verification, processing of identity documentation, collection of due diligence documentation, and/or transaction monitoring.<br/><br/>
            Under these arrangements, personal data may be provided to such eKYC service providers through the submission of information, forms, documents or media files (in whatever format) through an upload to online platforms operated by such eKYC service providers. The eKYC process may be automated, semi-automated or performed by a human.<br/><br/>
            The result of the eKYC process as well as all personal data provided to eKYC service providers will be made available solely to the relevant project conducting the token sale, and Nexa does not retain, distribute or share any of your identification documents provided (save for your declared wallet address). All eKYC service providers shall be required to ensure that the eKYC solution is secure and robust to protect personal data from unauthorised access, use and disclosure at all times.
          </Paragaph>
          <Headingparagraph>Use of personal data for marketing purposes</Headingparagraph>
          <Paragaph>
            We may use your personal data to offer you products or services, including special offers, promotions, contests or entitlements that may be of interest to you or for which you may be eligible. Such marketing messages may be sent to you in various modes including but not limited to electronic mail, direct mailers, short message service, telephone calls, facsimile and other mobile messaging services. In doing so, we will comply with all applicable data protection and privacy laws.<br/><br/>
            In respect of sending telemarketing messages to your telephone number via short message service, telephone calls, facsimile and other mobile messaging services, please be assured that we shall only do so if we have your clear and unambiguous consent in writing or other recorded form to do so or if you have not otherwise made the appropriate registration of that number with the Do Not Call Registry. If we have an ongoing relationship with you and you have not indicated to us that you do not wish to receive telemarketing messages sent to your telephone number, we may send you telemarketing messages to that number related to the subject of our ongoing relationship via short message service, facsimile and other mobile messaging services (other than a voice or video call).<br/><br/>
            You may at any time request that we stop contacting you for marketing purposes via selected or all modes.<br/><br/>
            To find out more on how you can change the way we use your personal data for marketing purposes, please contact us.<br/><br/>
            Nothing in this Policy shall vary or supersede the terms and conditions that govern our relationship with you.
          </Paragaph>
          <Headingparagraph>Disclosure and sharing of personal data</Headingparagraph>
          <Paragaph>
            We may from time to time and in compliance with all applicable laws on data privacy, disclose your personal data to any personnel of Nexa, group entities, or to third parties (including without limitation banks, financial institutions, credit card companies, credit bureaus and their respective service providers, companies providing services relating to insurance and/or reinsurance to us, and associations of insurance companies, agents, contractors or third party service providers who provide services to us such as telecommunications, information technology, payment, data processing, storage and archival, and our professional advisers such as our auditors and lawyers, and regulators and authorities), located in any jurisdiction, in order to carry out the purposes set out above. Please be assured that when we disclose your personal data to such parties, we will disclose only the personal information that is necessary to deliver the service required, and will also require them to ensure that any personal data disclosed to them are kept confidential and secure.<br/><br/>
            For more information about the third parties with whom we share your personal data, you may, where appropriate, wish to refer to the agreement(s) and/or terms and conditions that govern our relationship with you or our customer. You may also contact us for more information (please see section 10 below).<br/><br/>
            We wish to emphasise that Nexa does not sell personal data to any third parties and we shall remain fully compliant of any duty or obligation of confidentiality imposed on us under the applicable agreement(s) and/or terms and conditions that govern our relationship with you or our customer or any applicable law.<br/><br/>
            You are responsible for ensuring that the personal data you provide to us is accurate, complete, and not misleading and that such personal data is kept up to date. You acknowledge that failure on your part to do so may result in our inability to provide you with the products and services you have requested. To update your personal data, please contact us (please see section 10 below for contact details). Where you provide us with personal data concerning individuals other than yourself, you are responsible for obtaining all legally required consents from the concerned individuals and you shall retain proof of such consent(s), such proof to be provided to us upon our request.<br/><br/>
            We may transfer, store, process and/or deal with your personal data in any jurisdiction, and accordingly such personal data may be transferred to computers, servers or hardware located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. Nexa will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Policy and no transfer of your personal data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information (including without limitation the Standard Contractual Clauses approved by the European Commission). Your consent to this Policy followed by your submission of such information represents your agreement to the transfer of personal data as described herein.
          </Paragaph>
          <Headingparagraph>Cookies and related technologies</Headingparagraph>
          <Paragaph>
            The Website uses cookies. A cookie is a small text file placed on your computer or mobile device when you visit a Website or use an app, which may include an anonymous unique identifier. Cookies collect information about users and their visit to the Website or use of the app, such as their Internet protocol (IP) address, how they arrived at the Website (for example, through a search engine or a link from another Website), how they navigate within the Website or app, browser information, computer or device type, operating system, internet service provider, website usage, referring/exit pages, platform type, date/time stamp, number of clicks, ads viewed, and how they use our services. We use cookies and other technologies to facilitate your internet sessions and use of our apps, offer you customised products and/or services according to your preferred settings, display features and services which might be of interest to you (including ads on our services), track use of our websites and apps, to compile statistics about activities carried out on our websites, and to hold certain information. Examples of cookies which we use include, without limitation, Session Cookies to operate our service, Preference Cookies to remember your preferences and various settings, as well as Security Cookies for security purposes.<br/><br/>
            You may set up your web browser to block cookies from monitoring your website visit. You may also remove cookies stored from your computer or mobile device. However, if you do block cookies you may not be able to use certain features and functions of our web sites.<br/><br/>
            We further utilise a variety of other similar tracking technologies, including without limitation beacons, tags, and scripts to collect and track information and to improve and analyse our services.<br/>
          </Paragaph>
          <Headingparagraph>Other web sites</Headingparagraph>
          <Paragaph>
            Our websites may contain links to other websites which are not maintained by Nexa. This Policy only applies to the websites of Nexa. When visiting these third party websites, you should read their privacy policies which will apply to your use of such websites.
          </Paragaph>
          <Headingparagraph>Retention of personal data</Headingparagraph>
          <Paragaph>
            Your personal data is retained as long as the purpose for which it was collected remains and until it is no longer necessary for any legal or business purposes. This enables us to comply with legal and regulatory requirements or use it where we need to for our legitimate purposes, such as transfers of digital assets, and dealing with any disputes or concerns that may arise.<br/><br/>
            We may need to retain information for a longer period where we need the information to comply with regulatory or legal requirements or where we may need it for our legitimate purposes (e.g. to help us respond to queries or complaints, fighting fraud and financial crime, responding to requests from regulators etc).<br/><br/>
            When we no longer need to use personal data, we will remove it from our systems and records and/or take steps to anonymise it so that you can no longer be identified from it.
          </Paragaph>
          <Headingparagraph>Queries, Access/Correction Requests and Withdrawal of Consent</Headingparagraph>
          <Paragaph>If you:</Paragaph>
          <List>
            <span className='listSeries'>(a)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> have queries about our data protection processes and practices; </Flex>
          </List>
          <List>
            <span className='listSeries'>(b)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> wish to request access to and/or make corrections to your personal data in our possession or under our control; or </Flex>
          </List>
          <List>
            <span className='listSeries'>(c)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'} line-height={'24px'}> wish to withdraw your consent to our collection, use or disclosure of your personal data,<br/>
              please submit a written request (with supporting documents, (if any) to our Data Protection Officer at: privacy@nexa.network. Our Data Protection Officer shall respond to you within 30 days of your submission. Please note that if you withdraw your consent to any or all use or disclosure of your personal data, depending on the nature of your request, we may not be in a position to continue to provide our services or products to you or administer any contractual relationship in place. Such withdrawal may also result in the termination of any agreement you may have with us. Our legal rights and remedies are expressly reserved in such event.<br/><br/>
              We may charge you a fee for processing your request for access. Such a fee depends on the nature and complexity of your access request. Information on the processing fee will be made available to you. </Flex>
          </List>
          <Headingparagraph>Contact information</Headingparagraph>
          <Paragaph>To contact us on any aspect of this Policy or your personal data or to provide any feedback that you may have, please contact our Data Protection Officer at privacy@nexa.network.</Paragaph>
          <Headingparagraph>Governing Law and Jurisdiction</Headingparagraph>
          <Paragaph>This Policy and your use of the Website shall be governed and construed in accordance with the laws of Singapore. You agree to submit to the exclusive jurisdiction of the Singapore courts.</Paragaph>
          <Headingparagraph>Amendments and updates to Nexa Privacy Policy</Headingparagraph>
          <Paragaph>We reserve the right to amend this Policy from time to time to ensure that this Policy is consistent with any developments to the way Nexa uses your personal data or any changes to the laws and regulations applicable to Nexa. We will make available the updated Policy on the Website. You are encouraged to visit the Website from time to time to ensure that you are well informed of our latest policies in relation to personal data protection. All communications, transactions and dealings with us shall be subject to the latest version of this Policy in force at the time.</Paragaph>
          <Headingparagraph>For European Union or European Economic Area Residents</Headingparagraph>
          <Paragaph>This section 13 applies if you are an individual located in the European Union or European Economic Area. Subject to applicable law, you have the following additional rights in relation to your personal data:</Paragaph>
          <List>
            <span className='listSeries'>(a)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> the right to access your personal data (if you ask us, we will confirm whether we are processing your personal data in a structured, commonly used and machine-readable format and, if so, provide you with a copy of that personal data (along with certain other details). If you require additional copies, we may need to charge a reasonable fee; </Flex>
          </List>
          <List>
            <span className='listSeries'>(b)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> the right to ensure the accuracy of your personal data; </Flex>
          </List>
          <List>
            <span className='listSeries'>(c)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> the right to have us delete your personal data (we will do so in some circumstances, such as where we no longer need it, but do note that we may not delete your data when other interests outweigh your right to deletion); </Flex>
          </List>
          <List>
            <span className='listSeries'>(d)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> the right to restrict further processing of your personal data (unless we demonstrate compelling legitimate grounds for the processing); </Flex>
          </List>
          <List>
            <span className='listSeries'>(e)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> rights in relation to automated decision-making and profiling (you have the right to be free from decisions based solely on automated processing of your personal data, including profiling, that affect you, unless such processing is necessary for entering into, or the performance of, a contract between you and us or you provide your explicit consent to such processing); </Flex>
          </List>
          <List>
            <span className='listSeries'>(f)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> the right to withdraw consent (if we rely on your consent to process your personal data, you have the right to withdraw that consent at any time, but provided always that this shall not affect the lawfulness of processing based on your prior consent); and </Flex>
          </List>
          <List>
            <span className='listSeries'>(g)</span> 
            <Flex alignItems={'center'} padding={'0 50px'} marginTop={'-30px'}> the right to complain to a supervisory authority in your country of residence in the event that data is misused. </Flex>
          </List>
          <Paragaph>If you believe that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. You may do so in the EU member state of your residence, your place of work or the place of the alleged infringement. You may exercise any of your rights in relation to your personal data by contacting our Data Protection Officer at: privacy@nexa.network, and we shall respond to you within 30 days of your submission.</Paragaph>
          <Headingparagraph>Your acceptance of these terms</Headingparagraph>
          <Paragaph>This Policy applies in conjunction with any other notices, contractual clauses and consent clauses that apply in relation to the collection, use and disclosure of your personal data by us. We may revise this Policy from time to time without any prior notice. You may determine if any such revision has taken place by referring to the date on which this Policy was last updated.<br/><br/>
          By using the Website and/or any services provided by Nexa, you signify your acceptance of this Policy and terms of service. If you do not agree to this Policy or terms of service, please do not use the Website or any services provided by Nexa. Your continued use of the Website following the posting of changes to this Policy will be deemed your acceptance of those changes.
          </Paragaph>
          
          <List marginTop={'56px !important'}  mb={'80px !important'}  className='listBottom'>Last updated: <span color='#FFFFFF'>16 September 2022</span></List>
        </Flex>
    </PageContainer>
    <TopLight/>
    <RightLight />
    </>
  )
}

export default Privacypolicy