import { BigNumber, BigNumberish } from 'ethers'
import { STATUS_STATE } from 'config/constants/types'
import { SupportedChainId } from 'config/constants/chains'

export function toBigNumberHex(value: BigNumberish, numBytes: number): string {
  return BigNumber.from(value)
    .toHexString()
    .substring(2)
    .padStart(numBytes * 2, '0')
}

export const parseRevertReason = async (revertReason : string) =>  {
  const json = revertReason.slice(revertReason.indexOf('{'), revertReason.indexOf('}') + 1);
  return JSON.parse(json)
}

export const solanaSupportiveChains = new Set([
  SupportedChainId.SOLANA,
  SupportedChainId.SOLANA_DEV_NET
])

export const StatusStyles = {
  [STATUS_STATE.NO_STATUS]: {
    color: '#F57C00',
    bg: '#FBAF0033',
  },
  [STATUS_STATE.SCHEDULED]: {
    color: '#E4A62E',
    bg: '#FBAF0033',
  },
  [STATUS_STATE.ACTIVE]: {
    color: '#54AE57',
    bg: 'linear-gradient(180deg, rgba(111, 225, 115, 0.15) 0%, rgba(96, 228, 101, 0.15) 93.23%)',
  },
  [STATUS_STATE.CANCELED]: {
    color: '#FF3939',
    bg: '#FF181F26',
  },
  [STATUS_STATE.COMPLETE]: {
    color: '#A0A0A0',
    bg: '#A0A0A026',
  },
  [STATUS_STATE.SOLDOUT]: {
    color: '#5BC8AF',
    bg: '#5BC8AF33',
  },
  [STATUS_STATE.VESTING]: {
    color: '#1EA0FF',
    bg: '#1EA0FF26',
  },
}

export const tabHeadings = [
  {
    key: 'introduction',
    label: 'Introduction',
  },
  {
    key: 'features',
    label: 'Key features',
  },
  {
    key: 'problem',
    label: 'Problem',
  },
  {
    key: 'solution',
    label: 'Solution',
  },
  {
    key: 'business',
    label: 'Business model',
  },
  {
    key: 'utility',
    label: 'Token utility',
  },
  {
    key: 'roadmap',
    label: 'Roadmap',
  },
  {
    key: 'backers',
    label: 'Backers',
  },
  {
    key: 'team',
    label: 'Team',
  },
]

export const dummyText =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna quis vestibulum magna in sed dapibus posuere gravida ridiculus. Vestibulum, aliquam amet sollicitudin Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna quis vestibulum magna in sed dapibus posuere gravida ridiculus. Vestibulum, aliquam amet sollicitudin '
