import styled from 'styled-components'
import { Box, Flex } from 'components/Box'
import { Tabs } from 'react-bootstrap'

export const PageContainer = styled(Box)<{ width?: string }>`
  margin: 0 auto;
`
export const BackgroundLight = styled(Box)`
  background-color: ${({ theme }) => theme.colors.backgroundDetailPage};
  background-image: url(../assets/images/BGLights.png);
  background-size: 100% 500px;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

export const Tag = styled(Flex)`
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border-radius: 4px;
  padding: 0.4rem 0.8rem;
  width: fit-content;
  margin: 0.2rem;
`

export const PreviewImage = styled(Box)<{ imgUrl: string }>`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaQueries.md} {
    height: 300px;
  }
`

export const TabHeadingFlex = styled(Flex)<{ isActive?: boolean }>`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  .line {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    margin-top: 0.7rem;
    background: ${({ theme, isActive }) => (isActive ? theme.colors.buttonprimary : 'transparent')};
  }
  .heading-title {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.text : theme.colors.textDisabled)};
    font-size: 20px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: space-between;
    width: 100%;
    align-items: start;
    margin: 1rem 0;
    .heading-title {
      font-size: 11px;
    }
  }
`
export const TabsWrapper = styled(Tabs)`
  li {
    margin-left: 8px;
    margin-right: 8px;
  }
  margin: 0px !important;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
    justify-content: space-between;
  }
`
export const VestingProgresBar = styled(Box)`
  position: relative;
  margin-top: 1px;
  height: 18px;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  .text {
    color: ${({ theme }) => theme.colors.text};
    font-size: 12px;
    font-weight: 300;
  }
  .textDisabled {
    color: ${({ theme }) => theme.colors.textDisabled};
    font-size: 12px;
    font-weight: 300;
  }
  span {
    padding: 0 5px;
  }
  .bar-progess {
    top: 0;
    z-index: 1;
    background: linear-gradient(133.82deg, #513cff 13.2%, #23abd4 100%);
  }
  .wrapper-val {
    position: relative;
    height: 18px;
    span {
      z-index: 2;
    }
    background: linear-gradient(#2C3B44 0%, #1A2F3C 65.27%);
  }
`
