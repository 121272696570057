import styled from 'styled-components'
import { Flex } from 'components/Box'

export const MainHeader = styled(Flex)`
  width: 100%;
  margin: 48px auto;
  .info-contaner {
    & > img {
      width: 120px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
    .discription-image {
      margin-top: 15px;
      margin-left: -87px;
    }
    }
  }
`

export const Tag = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0.4rem 0.8rem;
  width: fit-content;
  margin: 0.2rem;
`

export const SubscribeButton = styled.button`
  margin-left: auto;
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.colors.background}40;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primary};
  border: solid 1px ${({ theme }) => theme.colors.primary};
  outline: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.bold};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
`

export const ProjectLogo = styled.img`
  border-radius: 12px;
  ${({ theme }) => theme.mediaQueries.sm} {
    border-radius: 4px;
  }
`
export const LinkContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  a {
    text-decoration: none;
    svg {
      filter: brightness(0) invert(1);
      &:hover {
        filter:none;
      }
    }
  }
  ${({ theme }) => theme.mediaQueries.md} {
  margin-bottom: 0;
  }
`
