import styled from 'styled-components'
import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import { Accordion as AccordionMain } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { Col } from 'react-bootstrap'

export const ProfileHeading = styled(Text)`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.semiBold};
  font-size: 32px;
  margin-right: 1.8rem;
`

export const VerificationBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.tabColor};
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 24px;
  flex-direction: column;
  position: relative;
  font-family: ${({ theme }) => theme.fonts.primary};
  .title {
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ theme }) => theme.fonts.medium};
    font-size: 24px;
  }
  .sub-text {
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ theme }) => theme.fonts.light};
    font-size: 12px;
  }
  .finger-print {
    position: absolute;
    right: 50px;
    bottom: 0px;
    top: 42px;
    width: 281px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    .finger-print {
      position: static;
      margin-top: 114px;
      margin-bottom: -100px;
      width: 100%;
      max-width: 219px;
    }
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    .finger-print {
      margin-bottom: -80px;
      max-width: 219px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 85px;
    }
  }
`
export const Accordion = styled(AccordionMain)`
  .accordion-item {
    border: none;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.tabColor};
    padding: 20px 30px;
    margin-bottom: 20px;
    .accordion-button {
      background: none;
      border: none;
      padding: 0;
      width: 100%;
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: ${({ theme }) => theme.fonts.medium};
      font-size: 24px;
      color: ${({ theme }) => theme.colors.text};
      &:hover {
        background: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .accordion-button::after {
      background-image: url('../assets/images/Vector.svg');
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url('../assets/images/Vector.svg');
      transform: rotate(180deg);
    }
    .accordion-button:not(.collapsed) {
      box-shadow: none !important;
      border: none;
      outline: none;
    }
    .accordion-collapse {
      border: none;
      background: none;
      .accordion-body {
        border: none;
        padding: 0;
        margin-top: 20px;
        font-family: ${({ theme }) => theme.fonts.primary};
        font-weight: ${({ theme }) => theme.fonts.light};
        font-size: 12px;
        color: ${({ theme }) => theme.colors.text};
        .email-input {
          background: #201d1d;
          border: none;
          color: ${({ theme }) => theme.colors.text};
          font-family: ${({ theme }) => theme.fonts.primary};
          font-weight: ${({ theme }) => theme.fonts.light};
          font-size: 12px;
          border-radius: 4px;
          padding: 16px;
          margin-top: 10px;
          width: 90%;
          &:focus {
            box-shadow: none;
            border: none;
            outline: none;
          }
        }
        .email-input.error {
          border: 1px solid ${({ theme }) => theme.colors.failure};
          margin-bottom: 5px;
        }
        .checkbox-text {
          font-family: ${({ theme }) => theme.fonts.primary};
          font-weight: ${({ theme }) => theme.fonts.light};
          font-size: 14px;
          margin-left: 10px;
          margin-top: 2px;
          color: ${({ theme }) => theme.colors.text};
        }
      }
    }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    .accordion-button {
      flex-direction: column;
      align-items: flex-start;
    }
    .accordion-button::after {
      position: absolute;
      right: 0;
      top: 27px !important;
    }
    .accordion-item .accordion-button {
      padding-right: 30px;
    }
  }
`
export const FormContainer = styled(Form)`
  .form-check-input {
    margin-top: 0px !important;
  }
  .form-check-input:checked {
    border-color: none;
    margin-top: 0px !important;
  }
`

export const ListBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.tabColor};
  border-radius: 4px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
  flex-direction: column;
  height: fit-content;
  min-height: 0;
  max-height: 547px;
  ${({ theme }) => theme.mediaQueries.sm} {
    min-height: none;
  }
  .arrow-down-list {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    content: '';
    background-image: url(../assets/images/Vector.svg);
    background-repeat: no-repeat;
    background-size: 0.7rem;
    background-position: center;
    transition: transform 0.2s ease-in-out;
    filter: brightness(100);
    &.show {
      transform: rotate(180deg);
    }
  }
  .width-tet {
    width: calc(100% - 100px);
  }
  padding-bottom: 20px;
  .title {
    padding: 20px 24px !important;
  }
  .scrollBar {
    flex-direction: column;

    overflow-y: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      width: 2px;
      background: none;
    }
    ::-webkit-scrollbar-track {
      background: none;
    }
    ::-webkit-scrollbar-thumb {
      background: #a0a0a0;
      width: 2px;
      border-radius: 4px;
    }
  }

  .title {
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ theme }) => theme.fonts.medium};
    font-size: 24px;
    padding: 20px 30px;
  }
  .list-item {
    padding: 8px 16px 8px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 8px 16px 8px 16px;
      background: #201d1d;
    }
    .list-item-text {
      color: ${({ theme }) => theme.colors.text};
      font-weight: ${({ theme }) => theme.fonts.medium};
      font-size: 20px;
    }
  }
  .selected {
    background: ${({ theme }) => theme.colors.hover}1A;
  }
`

export const TabDetails = styled(Flex)`
  flex: 3;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.primary};
  .sale-text {
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ theme }) => theme.fonts.medium};
    font-size: 24px;
    ${({ theme }) => theme.mediaQueries.sm} {
      max-width: 200px;
      word-break: break-word;
    }
  }
`

export const HistoryWraper = styled(Flex)`
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.primary};
  margin-top: 20px;
  .title {
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ theme }) => theme.fonts.medium};
    font-size: 24px;
    margin-bottom: 20px;
  }
  .text-dis {
    color: ${({ theme }) => theme.colors.textDisabled};
    font-weight: ${({ theme }) => theme.fonts.light};
    font-size: 16px;
  }
  .text-np {
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    margin-left: 20px;
  }
  .des {
    flex-direction: column;
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
  }
  .disable {
    color: ${({ theme }) => theme.colors.textDisabled};
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fonts.medium};
  }
  .border-low {
    border-top: 1px solid ${({ theme }) => theme.colors.textDisabled}90;
  }
  .explorer {
    color: ${({ theme }) => theme.colors.link};
    font-weight: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    margin-left: 0px;
  }
  button[aria-label='chain'] {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fonts.regular};
    width: 250px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    & > div {
      flex-direction: column;
      padding: 14px 14px;
    }
    .des {
      flex-direction: column;
      div {
        margin-left: 0;
        word-break: break-all;
      }
    }
    .text-np {
      margin-left: 0;
    }
    .input-box {
      width: 100%;
    }
    button[aria-label='chain'] {
      font-weight: ${({ theme }) => theme.fonts.light};
      width: 100%;
    }
  }

  .accordion {
    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 0;
    }
  }
`

export const CheckBoxForm = styled(Flex)`
  cursor: pointer;
  align-items: center;
`

export const EmptyAccordion = styled(Flex)`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 14px 30px;
  justify-content: space-between;
`

export const ChainLineBg = styled(Col)`
  background-image: url('../assets/images/line.svg');
  background-repeat: no-repeat;
  height: 566px;
  padding-right: 0;
  background-position: right 0px top 0;
  background-origin: content-box;
  background-size: auto 100%;

  @media (max-width: 520px) {
    background-size: 60px auto;
    padding-right: 0;
    background-position: right center;
  }
`

export const LineBackground = styled(Box)`
  background-image: url('../assets/images/circles.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 800px;
`

export const TopLight = styled(Box)`
  background-color: ${({ theme }) => theme.colors.backgroundDetailPage};
  background-image: url(../assets/images/ProfileLights.png);
  background-size: 100% 520px;
  background-repeat: no-repeat;
  background-position: top;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  ${({ theme }) => theme.mediaQueries.md} {
    background-size: 1600px 650px;
    background-position: bottom right;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    background-position: top right;
  }
`

export const RightLight = styled(Box)`
  background-color: ${({ theme }) => theme.colors.backgroundDetailPage};
  background-image: url(../assets/images/RightLight.png);
  background-size: 20% 100%;
  background-repeat: no-repeat;
  height: 583px;
  background-position: right 0px top 0;
  opacity: 0.8;
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

export const StatusTags = styled(Flex)<{ tabStyle?: { color: string; bg: string }; radius?: string; padding?: string }>`
  background: ${({ tabStyle }) => tabStyle.bg};
  padding: ${({ padding }) => (padding ? padding : '0.4rem 1rem')};
  border-radius: ${({ radius }) => (radius ? radius : '4px')};
  color: ${({ tabStyle }) => tabStyle.color};
  align-items: center;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.medium};
  text-transform: capitalize;
`

export const BackButton = styled(Box)<{ borderRadius?: string; isGradient?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  font-family: ${({ theme }) => theme.fonts.secondary};
  height: 100%;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  &:before {
    content: '';
    border: 1px solid transparent;
    background: linear-gradient(133.82deg, #513cff 13.2%, #23abd4 100%) border-box;
    -webkit-mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) padding-box padding-box,
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    -webkit-mask-composite: xor;
    -webkit-mask-composite: exclude;
    width: 100%;
    position: absolute;
    white-space: nowrap;
    inset: 0px;
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '4px')};
    pointer-events: none;
  }
  .inner {
    background-image: ${({ theme, isGradient }) => !isGradient && theme.colors.buttonprimary};
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme, isGradient }) => (isGradient ? theme.colors.link : 'transparent')};
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: flex;
    white-space: nowrap;
    align-items: center;
  }
`
